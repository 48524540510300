import React,{useState, useEffect} from 'react';
import axios from 'axios';
import Footer from './Footer'
import Header from './Header'
import Sidebar from './Sidebar'
import {NavLink, useParams} from 'react-router-dom';
import {Helmet} from "react-helmet";

const Category = ()=>{

const [cateData, setCateData] = useState({})
const [latData, setLatestData] = useState({}) 
const {categoryId, subCat, offset} = useParams();
const [currentPage, setCurrentPage] = useState(1);
const [pageList, setPageList] = useState([1,2,3]);
const [lastPage, setLastPage] = useState(1);
const [lt, setLt] = useState(0);
const [requestFlag, setRequestFlag] = useState(true);
const LatestData =()=>{
    axios
    .get(
    `https://cms.iansnews.in/api/news/portals/?language=english&website=2`
    )
    .then((res) => {
    const latestStories =
        res.data.results &&
        res.data.results.length > 0 &&
        res.data.results.slice(0, 8).map((item) => {
        return {
            byline: item.byline,
            content: item.short_desc,
            id: item.id,
            image: item.thumbnail,
            title: item.title,
            slug:'/'+item.slug,
            image_caption: item.image_caption,
        };
        });
    setLatestData(latestStories);
    });
}
//alert(categoryId);
const fetchData = async (page) => {
const getApii = `https://cms.iansnews.in/api/news/portals/?language=english&website=2&tags=${categoryId}&page=${page}`;
   axios.get(getApii)
     .then(res => {

       const capitalizedCatText = categoryId ? categoryId.toUpperCase(): '';
       const capitalizedSubCatText = subCat ? ' / ' + subCat.charAt(0).toUpperCase() +  subCat.slice(1) : '';
if(lastPage == 1){
setLastPage(res.data.numpages);

}
const getCatData =
res.data.results &&
res.data.results.length > 0 &&
res.data.results.map((item) => {
return {
    byline: item.byline,
    content: item.short_desc,
    id: item.id,
    image: item.thumbnail,
    title: item.title,
    slug:'/'+item.slug,
    image_caption: item.image_caption,
};
});
        setCateData(getCatData);
        setLt(res.data.numpages);
        setRequestFlag(false);
   })
   .catch(err => {
     // console.log('Google api calendar error', err)
    })

}
const [isVisible, setIsVisible] = useState(false);

const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };
const toggleVisibility = () => {
    if (window.pageYOffset > 500) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };
useEffect(() => {
  fetchData(currentPage);
  if(currentPage && currentPage == 1){
    LatestData();
  }
  scrollToTop();
  window.addEventListener("scroll", toggleVisibility);

  return () => window.removeEventListener("scroll", toggleVisibility);
 
//  .catch(err => { console.log('Google api calendar error', err) })
}, [currentPage, categoryId])

const handleNextPage = () => {
if(lastPage > currentPage){
  setCurrentPage(currentPage + 1);
  let newArry = pageList;
  let newAddArray = pageList[pageList.length-1]+1;
  // if(currentPage > 2){
    let newRemoveArray = pageList[0];
  //
  // }
  newArry.shift(newRemoveArray);
  newArry.push(newAddArray);
    setPageList(newArry);

   }

}

const handlePrevPage = () => {
if(currentPage > 1){

  setCurrentPage(currentPage - 1);
  // setCurrentPage(currentPage + 1);
  let newArry = pageList;

  let newAddArray = pageList[pageList.length-1];
  let newRemoveArray = pageList[0]-1;
    newArry.unshift(newRemoveArray);
  newArry.pop(newAddArray);
setPageList(newArry);
}}


    return(
        <>
        <Helmet>
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
            <title>IANS LIVE -{categoryId ? categoryId.toUpperCase() : ''} NEWS</title>
            <meta data-meta-title="IANS LIVE" name="keywords" content="IANS LIVE, IANS LIVE News, latest updates, live updates, business updates, Indian news, online news portal, Bollywood updates, breaking headlines, current events, global news, sports coverage, latest India updates, news highlights, news platform, top stories, India updates, entertainment buzz, national affairs, political happenings" />
            <meta data-meta-title="IANS LIVE" name="description" content="Stay informed with IANS LIVE News, your ultimate source for the latest updates, live news, and business developments. Get the most comprehensive coverage of news in India, including Bollywood updates and breaking headlines. Stay up-to-date with current events and global news, from sports updates to the latest happenings in India. Our news portal brings you the top stories, entertainment buzz, national affairs, and political developments, making it your go-to destination for staying in the know." />
        </Helmet>
        <div id="wrapper" className="wrapper">
            <div id="main_content" className="footer-fixed">
                <Header />
                <main>
                    <section className="rt-sidebar-section-layout-1">
                        <div className="container">
                            <div className="col-12">
                                <h2 className="rt-section-heading">
                                    <span className="rt-section-text">{categoryId.toUpperCase()}</span>
                                    <span className="rt-section-dot"></span>
                                    <span className="rt-section-line"></span>
                                </h2>
                            </div>
                            <div className="row gutter-40 sticky-coloum-wrap">
                                <div className="col-xl-9 sticky-coloum-item">
                                    <div className="rt-left-sidebar-sapcer-5">
                                        <div className="row gutter-24">
                                            {cateData && cateData.length > 0 && cateData.slice(0, 9).map((ct, c)=>{
                                                return(
                                                    <div className="col-md-4 wow fadeInUp" data-wow-delay="100ms" data-wow-duration="800ms">
                                                    <div className="rt-post-grid post-grid-style-2 grid-meta ">
                                                        <div className="post-img">
                                                            <NavLink to={ct.slug}>
                                                                <img src={ct.image} alt={ct.image_caption} width="551" height="431" />
                                                            </NavLink>
                                                        </div>
                                                        <div className="post-content">
                                                            <h3 className="post-title bold-underline">
                                                                <NavLink to={ct.slug}>
                                                                {ct.title}
                                                                </NavLink>
                                                            </h3>
                                                            <p>{ct.content}</p>
                                                        </div>
                                                    </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        <nav className="rt-pagination-area gap-top-90">
                                            <ul className="pagination rt-pagination justify-content-center">
                                            <li className="page-item prev" onClick={handlePrevPage} disabled={currentPage === 1}>
                                                <a className="page-link" href="#"><i className="fas fa-angle-double-left"></i></a>
                                            </li>
{  currentPage && currentPage > 1 &&  
                                        <li className="page-item" onClick={()=>{
                                            setCurrentPage(currentPage-1)}}>
                                                <a href="#" class="page-link">{currentPage-1}</a>
                                            </li> 
                                            } 
                                             {lt && lt > 1 &&  
                                            <li className="active page-item">
                                             <a href="#" class="page-link">{currentPage}</a>
                                            </li>
                                            }                                         
                                        {lt && lt > 2 && 
                                            <li className="page-item" onClick={()=>{
                                            setCurrentPage(currentPage+1)}}>
                                                <a href="#" class="page-link">{currentPage+1}</a>
                                            </li> 
                                        } 
                                            <li className="page-item" onClick={()=>{
                                            setCurrentPage(currentPage+2)}}>
                                                <a href="#" class="page-link">{currentPage+2}</a>
                                            </li>                                                                                      
                                            {/* {pageList.map((data, i)=>{
                                            return(
                                                <li className={currentPage == data ? 'active page-item' : 'page-item'} key={i} onClick={()=>{
                                            setCurrentPage(data)}}><a href="#" className="page-link">{data}</a></li>
                                                        )
                                                    })} */}
                                            <li className="page-item next" onClick={handleNextPage} disabled={requestFlag}>
                                                <a className="page-link" href="#"><i className="fas fa-angle-double-right"></i></a>
                                            </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                                <Sidebar data={latData} />
                            </div>
                        </div>
                    </section>
                </main>
                <Footer />
            </div>
            <a href="javascript:void(0)" id="back-to-top">
            <i className="fas fa-angle-double-up"></i>
            </a>
        </div>
        </>
    )
}
export default Category