import { NavLink } from 'react-router-dom'

const Footer = () =>{
    const year = new Date().getFullYear();

    return (
        <>
        <footer className="footer footer-style-4">
            <div className="container">
                <div className="footer-widget-style-2 d-flex align-items-center justify-content-center text-center flex-column">
                    <div className="logo wow fadeInUp animated abcWOW1" data-wow-delay="200ms" data-wow-duration="800ms">
                        <NavLink to ="/">
                            <img src="/assets/Images/logo-ianslive.png" alt="logo-classNameic" width="170" height="50" />
                        </NavLink>
                    </div>
                    <ul className="footer-social wow fadeInUp animated abcWOW2" data-wow-delay="400ms" data-wow-duration="800ms">
                        <li className="social-item">
                            <a href="https://www.facebook.com/iansnews.in/" className="social-link fb" target="_blank">
                            <i className="fab fa-facebook-f"></i>
                            </a>
                        </li>
                        <li className="social-item">
                            <a href="https://twitter.com/ians_india" className="social-link tw" target="_blank">
                            <i className="fab fa-twitter"></i>
                            </a>
                        </li>
                        <li className="social-item">
                            <a href="https://www.instagram.com/iansnews.in/" className="social-link vm" target="_blank">
                            <i className="fab fa-instagram"></i>
                            </a>
                        </li>
                        <li className="social-item">
                            <a href="https://www.youtube.com/user/iansindia" className="social-link pn" target="_blank">
                            <i className="fab fa-youtube"></i>
                            </a>
                        </li>
                    </ul>
                    <ul className="list-unstyled">
                        <li><NavLink to ="/aboutus">About US</NavLink></li>
                        <li><NavLink to ="/privacy-policy">Privacy Policy </NavLink></li>
                        <li><NavLink to ="/terms-of-use">Terms Of Use</NavLink></li>
                        <li><NavLink to ="/contactus">Contact Us</NavLink></li>
                    </ul>
                    <p className="copyright-text mb-0 wow fadeInUp animated abcWOW" data-wow-delay="600ms" data-wow-duration="800ms">
                        <span className="currentYear">{year}</span> © all right reserved by
                        <a href="https://ians.in/" rel="nofollow" target="_blank">IANS</a>
                    </p>
                </div>
            </div>
        </footer>        
        </>
    )
}
export default Footer