import React from 'react';
import Home from './Home';
import ReactDOM from "react-dom";
import {BrowserRouter, Switch, Route, Redirect} from 'react-router-dom';
// import PhotoGalleryDetails from './PhotoGalleryDetails';
import Terms from './Terms';
import Privacy from './Privacy';
import Contact from './Contact';
import Category from './Category';

import Search from './Search';
import Detail from './Detail';
import About from './About';
// import Calulator from './Calulator';
import '../css/style.css';


const App = () =>{
  return(
    <>
    <BrowserRouter>
    <Switch>
    <Route exact path="/" component={Home}/>
    {/* <Route exact path="/search" component={Search}/> */}
    <Route exact path="/search/:search" component={Search}/>
    <Route exact path="/aboutus" component={About}/>
    <Route exact path="/contactus" component={Contact}/>

     <Route exact path="/privacy-policy" component={Privacy}/>
    <Route exact path="/terms-of-use" component={Terms}/>
   
    <Route exact path="/category/:categoryId" component={Category}/>
   
    <Route exact path="/:newsId" component={Detail}/>
    <Redirect to="/" />
    </Switch>
    </BrowserRouter>
    </>
  )
}

export default App;
